/* Copyright 2020 Avetti.com Corporation - All Rights Reserved

This source file is subject to the Avetti Commerce Front End License (ACFEL 1.20)
that is accessible at https://www.avetticommerce.com/license */
import React from "react";
import PropTypes from "prop-types";

import { Provider } from "react-redux";
import { I18nContextProvider } from "../shared/i18n/index";
import { IntlProvider } from "react-intl";
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-react-intl";

import configureStore from "../redux/index.js";

import "../assets/css/googleFonts.css";
import "../assets/css/menu.css";
import "../assets/css/minified.css";
import "../assets/css/avetti.css";
import "../assets/css/mailchimp.css";

import "../assets/css/style.css";
import "../assets/css/b2b2c.css";
import "../assets/css/fontello.css";
import "../assets/jss/demo-ar.scss";
import "../assets/css/purgeignore.css";

import handleError from "../functions/handleError";
import HOCLayout from "./hocLayout";

export const store = configureStore();

const Layout = ({ children, params }) => {
  if (typeof window !== "undefined") {
    window.addEventListener("error", function (event) {
      if (event.error.hasBeenCaught !== undefined) {
        return false;
      }
      event.error.hasBeenCaught = true;
      handleError(event.error);
    });
  }

  return (
    <I18nContextProvider>
      <IntlProvider locale={"en"}>
        <Provider store={store}>
          <HOCLayout children={children} params={params} />
        </Provider>
      </IntlProvider>
    </I18nContextProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
